.footerBox{
    width: 1440px;
    background: var(--purple, linear-gradient(92deg, #A647EA 0%, #E480FF 100%));
    padding: 48px 77px;
    position: relative;
    .footerPack{
        display: flex;
        align-items: center;
        justify-content: center;
        .footerL{
            display: flex;
            align-items: center;
            a{
                font-size: 0;
                .footerLogo{
                    width: 330px;
                    height: 80px;
                    margin-right: 60px;
                }
            }
            
            
        }
        .footerR{
            .footerTitlesPack{
                .footerTitlesTop{
                    display: flex;
                    align-items: center;
                    margin-bottom: 3px;
                    img{
                        width: 50px;
                        margin-right: 10px;
                    }
                    p{
                        font-size: 12px;
                        line-height: 19px;
                        color: rgba(255, 255, 255, 0.60)
                    }
                    .h5{
                        display: none;
                    }
                }
                .footerText{
                    font-size: 16px;
                    color: #fff;
                    font-weight: 800;
                }
            }
        }
    }
    .copyrightInfo{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        color: #fff;
        font-size: 12px;
        .protocolPack{
            font-weight: 900;
            display: flex;
            align-items: center;
            // margin-top: 30px;
            margin-bottom: 20px;
            img{
                height: auto;
                width: 12px;
                margin: 0 40px;
            }
        }
        ul{
            display: none;
        }
    }
}
@media (max-width: 600px) {
    .footerBox{
        width: 100%;
        padding: 0.4rem 0.27rem 0.3rem 0.27rem;
        .footerPack{
            flex-direction: column;
            .footerL{
                flex-direction: column;
                align-items: flex-start;
                a{
                    .footerLogo{
                        width: 2.47rem;
                        height: 0.6rem;
                        margin-right: 0;
                    }
                }
                
                .footerTitlesPack{
                    margin-top: 0.4rem;
                    .footerTitlesTop{
                        margin-bottom: 0.02rem;
                        margin-left: 0;
                        img{
                            width: 0.64rem;
                            margin-right: 0.08rem;
                        }
                        p{
                            font-size: 0.11rem;
                            line-height: 0.18rem;
                        }
                        .pc{
                            display: none;
                        }
                        .h5{
                            display: block;
                        }
                    }
                    .footerText{
                        font-size: 0.14rem;
                    }
                }
            }
            .footerR{
                margin-top: 0.25rem;
                width: 2.8rem;
                flex-wrap: wrap;
                justify-content: center;
                img{
                    width: 0.64rem!important;
                    height: 0.64rem;
                    margin-right: 0.08rem;
                }
                p{
                    font-size: 0.12rem!important;
                    line-height: 19px;
                }
            }
        }
        .copyrightInfo{
            flex-direction: column-reverse;
            p{
                text-align: center;
                font-size: 0.11rem;
            }
            .protocolPack{
                margin-top: 0.24rem;
                font-size: 0.12rem;
                div{
                    margin: 0 0.4rem;
                }
                img{
                    width: 0.12rem;
                    height: 0.12rem;
                    margin: 0 0;
                }
            }
            ul{
                display: flex;
                align-items: center;
                justify-content: center;
                li{
                    margin: 0.26rem 0.15rem;
                    position: relative;
                    img{
                        width: 0.4rem;
                        height: 0.4rem;
                    }
                    .hovPack{
                        position: absolute;
                        left: -1.2rem;
                        top: -1.7rem;
                        width: 1.57rem;
                        background: #fff;
                        div{
                            height: 0.4rem;
                            line-height: 0.4rem;
                            color: #A647EA;
                            font-size: 0.16rem;
                            text-align: center;
                        }
                        div:hover{
                            background: var(--Linear, linear-gradient(90deg, #A647EA 0%, #BD75F3 43.5%, #DAB0FF 100%));
                            color: #fff;
                        }
                    }
                }
                // li:hover .hovPack{
                //     display: block;
                // }
            }
        }
    }
}